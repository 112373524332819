<template>
  <section class="container px15 mt60 feedback">
    <div class="row center-xs py20 bg-cl-white">
      <p class="m0 cl-alternative h2 weight-500">
        {{ $t("Why do customers choose AG Grzejniki Design?") }}
      </p>
    </div>
    <div class="row center-xs py20 bg-cl-white">
      <p class="m0 cl-alternative">
        {{ $t("99% positive feedback") }}
      </p>
    </div>
    <div class="row center-xs py10 bg-cl-white brdr-top-2 brdr-bottom-2 brdr-cl-white-smoke">
      <p class="m0 cl-alternative cl-yellow">
        <span class="score">4.8</span>/5.0 &#9733; &#9733; &#9733; &#9733; &#9733; (160)
      </p>
    </div>
    <div class="row py15 bg-cl-white around-xs">
      <div class="col-md-4">
        <p class="fs-medium cl-yellow">
          5.0 &#9733; &#9733; &#9733; &#9733; &#9733;
        </p>
        <p class="italic">
          Ekspozycja mega! Największy wybór grzejników od ręki w WWA!
        </p>
        <p class="end-xs weight-600">
          Dominika
        </p>
      </div>
      <div class="col-md-4">
        <p class="fs-medium cl-yellow">
          5.0 &#9733; &#9733; &#9733; &#9733; &#9733;
        </p>
        <p class="italic">
          Piękne, doskonale jakościowo i wykonane z niezwykła starannością. Profesjonalna, bardzo pomocna obsługa. Polecam bardzo!
        </p>
        <p class="end-xs weight-600">
          Monika
        </p>
      </div>
    </div>
    <!-- <div class="row bg-cl-white end-xs py20 learn-more">
      <router-link to="#" class="cl-dark-blue fs-medium-small">
        {{ $t("More reviews") }} >
      </router-link>
    </div> -->
  </section>
</template>

<script>

export default {
}
</script>
