<template>
  <div class="inpirations-carousel">
    <div class="row center-xs py20 bg-cl-white">
      <p class="m0 cl-alternative h2 weight-500">
        Inspiracje
      </p>
    </div>
    <div class="row center-xs py20 bg-cl-white">
      <p class="m0 cl-alternative">
        REALIZACJE I POMYSŁY
      </p>
    </div>
    <product-gallery-overlay
      v-if="isZoomOpen"
      :current-slide="currentSlide"
      :gallery="gallery"
      :show-product-image="true"
      @close="toggleZoom"
    />
    <carousel
      v-bind="sliderConfig"
      @pageChange="setMuted"
      ref="inspirations"
      :infinite="true"
      class="row bg-cl-white"
    >
      <slide
        class="image-box"
        v-for="(banner, index) in validBanners"
        :key="index"
        @slideclick="handleSlideClick(banner, index)"
      >
        <div v-if="showImage(index)" class="offer pointer bg-cl-white-smoke">
          <img class="offer-image" alt="offer-image" v-lazy="banner.src">
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { onlineHelper } from '@vue-storefront/core/helpers'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import ProductGalleryOverlay from './ProductGalleryOverlay'
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress'

export default {
  name: 'InspirationsCarousel',
  components: {
    ProductGalleryOverlay,
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide)
  },
  mixins: [onEscapePress],
  data () {
    return {
      gallery: [],
      validBanners: {},
      sliderConfig: {
        perPage: 1,
        paginationActiveColor: '#3859A6',
        paginationColor: '#D9D9D9',
        navigationEnabled: true,
        paginationEnabled: true,
        autoplay: false,
        perPageCustom: [[767, 3]],
        navigationNextLabel: '&#10095;',
        navigationPrevLabel: '&#10094;'
      },
      isZoomOpen: false,
      currentSlide: 0,
      currentPage: 0
    }
  },
  mounted () {
    if (this.data) {
      this.blocksContent()
    }
  },
  watch: {
    data: function () {
      this.blocksContent()
    }
  },
  computed: {
    data () {
      return this.$store.getters[`cmsBlock/cmsBlocks`]
    }
  },
  methods: {
    showImage (index) {
      let perPage = this.$refs.inspirations && this.$refs.inspirations.currentPerPage
      return (this.currentPage + 1) * perPage >= index
    },
    setMuted (currentPage) {
      this.currentPage = currentPage
    },
    handleSlideClick (banner, index) {
      // console.log(this.$refs.inspirations)
      this.currentSlide = Number(index) - 1
      this.toggleZoom()
    },
    toggleZoom () {
      this.isZoomOpen = !this.isZoomOpen
    },
    onEscapePress () {
      if (this.isZoomOpen) {
        this.toggleZoom()
      }
    },
    getThumbnailPath (src) {
      return _thumbnailHelper(src, 850, 850, 'banners')
    },
    blocksContent () {
      if (this.data && this.data.length) {
        let banners = this.data
        let bannerInspiration = {}
        for (let banner of banners) {
          if (banner.identifier.includes('inspirations')) {
            bannerInspiration = banner.content.replace(/<\/?p>/g, '')
            bannerInspiration = JSON.parse(bannerInspiration)
            this.validBanners = bannerInspiration
            for (let item of Object.values(bannerInspiration)) {
              item.src = this.getThumbnailPath(item.src)
              item.loading = item.src
              item.error = item.src
              this.gallery.push(item)
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.inpirations-carousel > .VueCarousel {
  .VueCarousel-navigation--disabled {
    opacity: 0 !important;
  }
  .VueCarousel-navigation-button {
    color: #FFF !important;
    background-color: #3859A6 !important;
    font-size: 20px !important;
  }
  .VueCarousel-navigation-next {
    right: 27px !important;
  }
  .VueCarousel-navigation-prev {
    left: 27px !important;
  }
  .VueCarousel-dot-container {
    margin: 0 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    .VueCarousel-dot {
      width: 10px !important;
      height: 10px !important;
      outline: none !important;
      padding: 20px 8px !important;
      margin: 0 !important;
      border-radius: 0 !important;
      @media (max-width: 767px) {
        padding: 10px 8px !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.inpirations-carousel {
  .offer {
    height: 100%;
    .offer-image {
      display: flex;
      height: 100%;
      width: 100%;
      max-width: 460px;
      max-height: 460px;
      background-size: cover;
      @media (max-width: 767px) {
        max-width: 400px;
        max-height: 400px;
      }
    }
  }
}
</style>
