<template>
  <section class="authorized-distributor container px15 mt60">
    <div class="row bg-cl-white center-xs py20 brdr-bottom-2 brdr-cl-white-smoke">
      <p class="m0 cl-alternative h2 uppercase weight-500 distributor-title">
        Autoryzowany dystrybutor :
      </p>
    </div>
    <div class="row bg-cl-white center-xs py20 brdr-bottom-2 brdr-cl-white-smoke">
      <p class="m0 cl-alternative uppercase distributor-subtitle">
        Ponad 50 europejskich marek w jednym miejscu
      </p>
    </div>
    <div class="hidden-xs">
      <div class="row bg-cl-white">
        <div v-for="(marka, index) in getMarki" :key="index + '-' + marka.id" class="col-md-3 align-center py20">
          <router-link :to="localizedRoute(marka.url_path)">
            <img v-lazy="getThumbnailPath(marka.thumbnail_image)" :alt="marka.name" width="auto" height="100px" class="distributor-image">
          </router-link>
        </div>
      </div>
    </div>
    <div class="visible-xs">
      <carousel
        v-bind="sliderConfig"
        :infinite="true"
      >
        <slide v-for="(markaMobile, indexMarka) in getMarki" :key="indexMarka" class="flex middle-xs">
          <div class="achievement-box">
            <router-link :to="localizedRoute(markaMobile.url_path)">
              <img v-lazy="getThumbnailPath(markaMobile.thumbnail_image)" :alt="markaMobile.name" class="distributor-image">
            </router-link>
          </div>
        </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'

export default {
  name: 'AuthorizedDistributor',
  props: {
  },
  components: {
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide)
  },
  mounted () {
    this.$store.dispatch('category/list', { parent: 10 })
  },
  data () {
    return {
      sliderConfig: {
        perPage: 3,
        navigationEnabled: false,
        paginationEnabled: true,
        paginationActiveColor: '#041D59',
        paginationColor: '#D9D9D9',
        paginationPosition: 'bottom-overlay',
        perPageCustom: [[767, 6]],
        centerMode: true
      }
    }
  },
  computed: {
    ...mapGetters({
      getCategories: 'category/getCategories'
    }),
    getMarki () {
      return this.getCategories.filter(category => category.parent_id === 10)
    }
  },
  methods: {
    getThumbnailPath (url) {
      return url ? _thumbnailHelper(url, 100, 100, 'category') : null
    }
  }
}
</script>

<style lang="scss">
.authorized-distributor {
  .VueCarousel {
    padding: 20px 0;
    background: #FFF;
    margin-left: -8px;
    margin-right: -8px;
  }
  .VueCarousel-inner {
    text-align: center !important;
  }
  .VueCarousel-dot-container {
    margin: 0 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    .VueCarousel-dot {
      width: 10px !important;
      height: 10px !important;
      outline: none !important;
      padding: 20px 8px !important;
      margin: 0 !important;
      border-radius: 0 !important;
      @media (max-width: 767px) {
        padding: 10px 8px !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.distributor-image {
  max-width: 100px;
}
.distributor-subtitle {
  @media (max-width: 767px) {
    font-size: 12px;
  }
}
.distributor-title {
  @media (max-width: 767px) {
    font-size: 20px;
  }
}
</style>
