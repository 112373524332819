<template>
  <section class="offers container mb30 px15 cl-black relative">
    <div class="row offers-section">
      <div class="col-xs-12 center-flex-text weight-500 cl-alternative offers-title h2">
        Popularne kategorie łazienkowe
      </div>
      <div
        :key="category.slug"
        v-for="(category, index) in visibleCategories"
        v-if="visibleCategories && visibleCategories.length"
        class="col-xs-6 col-md-special p0 offera-container"
        :class="isHide && index > 1 ? 'hidden-xs' : null"
      >
        <router-link class="w-100" :to="categoryLink(category)">
          <div class="visible-xs">
            <p class="center-flex-text m0 py25 cl-alternative">
              {{ category.name }}
            </p>
          </div>
          <div v-if="category.thumbnail_image" class="image-box">
            <img v-lazy="getThumbnailPath(category.thumbnail_image)" width="268" height="268" :alt="category.name">
          </div>
          <div class="hidden-xs">
            <p class="cl-alternative name-box center-flex-text m0 py25 fs-medium">
              {{ category.name }}
            </p>
          </div>
        </router-link>
      </div>
    </div>
    <div @click="isHide = !isHide" :style="isHide && 'bottom: inherit;'" class="action-btn uppercase absolute" :class="isHide ? 'dynaic-style bg-cl-alternative cl-white' : 'cl-alternative'">
      <template v-if="!isHide">
        zwiń
      </template>
      <template v-else>
        wszystkie
      </template>
      <i :style="isHide && 'margin-left: 15px; border-color: #FFF;'" :class="isHide ? 'down' : 'up'" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import config from 'config'

export default {
  name: 'PromotedOffers',
  props: {
    singleBanner: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isHide: true
    }
  },
  mounted () {
    this.$store.dispatch('category/list', { parent: 67 })
  },
  computed: {
    ...mapGetters(
      'category',
      ['getCategories']
    ),
    allCategories () {
      return this.getCategories
    },
    visibleCategories () {
      let categories = this.allCategories.filter(category => {
        return category.parent_id === 67
      })
      return categories.slice(0, 5)
    },
    mainCollectionsId () {
      return config.server.mainCollectionsId
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    getThumbnailPath (url) {
      if (url) {
        return _thumbnailHelper(url, 450, 450, 'category')
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dynaic-style {
  align-items: center !important;
  padding: 10px 35px !important;
}
.action-btn {
  right: 10px;
  bottom: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px 40px;
  border: 1px solid #041D59;
  border-radius: 19px;
  @media (min-width: 767px) {
    display: none;
  }
  i {
    margin-left: 30px;
    border: solid #041D59;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
  }
  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
.image-box {
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 767px) {
    padding-bottom: 15px;
  }
}
.offers-section {
  background: #FFF;
  @media (max-width: 767px) {
    background: none;
  }
}
.offers {
  margin-top: 60px;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
}
.offers-title {
  border-bottom: 2px solid #F5F5F5;
  height: 80px;
  @media (max-width: 767px) {
    font-size: 20px;
    background: #F5F5F5;
  }
}
.name-box {
  border-top: 8px solid #3859A6;
  text-align: center;
}
.offera-container {
  border-right: 2px solid #F5F5F5;
  &:last-child {
    border-right: 2px solid #FFF;
  }
  @media (max-width: 767px) {
    border-right: 5px solid #F5F5F5;
    &:nth-child(odd) {
      border-left: 5px solid #F5F5F5;
      border-right: none;
    }
    margin-bottom: 15px;
    background: #FFF;
  }
}
</style>
