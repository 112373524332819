<template>
  <div :class="changeStyle ? 'achievements-carousel-category' : 'achievements-carousel'">
    <no-ssr>
      <carousel
        v-bind="sliderConfig"
        :infinite="true"
      >
        <slide v-for="(ach, index) in achievements" :key="index">
          <div class="achievement-box" @mouseover="overTip(index, $event)" @mouseleave="leaveTip()">
            <img v-lazy="ach.src" :alt="ach.text" height="80" width="100" class="image">
            <p class="ach-title relative">
              {{ ach.text }}
              <span class="arrow-up hidden-xs" :class="{'arrow-up-category': changeStyle}" v-if="selectedTip && selectedTipText === index" />
            </p>
            <p v-if="selectedTip && selectedTipText === index" class="col-xs-12 m0 bg-cl-alternative cl-white tooltip-box" :class="{'tooltip-box-category': changeStyle}">
              {{ ach.tooltip }}
            </p>
          </div>
        </slide>
      </carousel>
      <div slot="placeholder" class="row pt10">
        <div class="col-xs-4 col-sm-2 placeholder">
          <div class="placeholder__image bg-cl-gainsboro" />
        </div>
        <div class="col-xs-4 col-sm-2 placeholder">
          <div class="placeholder__image bg-cl-gainsboro" />
        </div>
        <div class="col-xs-4 col-sm-2 placeholder">
          <div class="placeholder__image bg-cl-gainsboro" />
        </div>
        <div class="col-xs-4 col-sm-2 placeholder hidden-xs">
          <div class="placeholder__image bg-cl-gainsboro" />
        </div>
        <div class="col-xs-4 col-sm-2 placeholder hidden-xs">
          <div class="placeholder__image bg-cl-gainsboro" />
        </div>
        <div class="col-xs-4 col-sm-2 placeholder hidden-xs">
          <div class="placeholder__image bg-cl-gainsboro" />
        </div>
      </div>
    </no-ssr>
  </div>
</template>

<script>
import { onlineHelper } from '@vue-storefront/core/helpers'
import NoSSR from 'vue-no-ssr';

export default {
  name: 'AchievementsCarousel',
  components: {
    'no-ssr': NoSSR,
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide)
  },
  props: {
    changeStyle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      achievements: [
        {
          src: '/assets/svg/nr1.svg',
          text: 'Nr 1 w Polsce',
          tooltip: 'Jesteśmy największym sklepem z grzejnikami dekoracyjnymi w Polsce, co potwierdza ponad 50 tysiąc zadowolonych klientów'
        },
        {
          src: '/assets/svg/dostepnosc.svg',
          text: 'Dostępność',
          tooltip: 'Topowe produkty dostępne "od ręki", dzięki stałemu utrzymywaniu stanów magazynowych'
        },
        {
          src: '/assets/svg/poz_opinii.svg',
          text: '99% pozytywnych opinii'
        },
        {
          src: '/assets/svg/fachowe_doradztwo.svg',
          text: 'Fachowe doradztwo',
          tooltip: 'Nasz zespół doradztwa technicznego służy pomocą w zakresie doboru produktów'
        },
        {
          src: '/assets/svg/darmowa_dostawa.svg',
          text: 'Darmowa dostawa',
          tooltip: 'Bezpłatna dostawa na terenie Polski przy zamówieniach powyżej 5000zł'
        },
        {
          src: '/assets/svg/atr_ceny.svg',
          text: 'Atrakcyjne ceny',
          tooltip: 'W naszym sklepie znajdziesz produkty renomowanych marek w najlepszych cenach na rynku'
        }
      ],
      sliderConfig: {
        perPage: 3,
        navigationEnabled: true,
        paginationActiveColor: '#3859A6',
        paginationColor: '#D9D9D9',
        paginationPosition: 'bottom',
        paginationEnabled: true,
        perPageCustom: [[767, 6]],
        centerMode: true,
        navigationNextLabel: '&#10095;',
        navigationPrevLabel: '&#10094;'
      },
      selectedTip: false,
      selectedTipText: 99
    }
  },
  methods: {
    overTip (index, e) {
      this.selectedTip = true
      if (index === 2) {
        this.selectedTipText = 99
      } else {
        this.selectedTipText = index
      }
    },
    leaveTip () {
      this.selectedTip = false
    }
  }
}
</script>

<style lang="scss">
.achievements-carousel {
  .VueCarousel-inner {
    text-align: center !important;
  }
  .VueCarousel-navigation--disabled {
    opacity: 0 !important;
  }
  .VueCarousel-navigation-button {
    color: #FFF !important;
    background-color: #3859A6 !important;
    font-size: 20px !important;
  }
  .VueCarousel-navigation-next {
    right: 5px !important;
  }
  .VueCarousel-navigation-prev {
    left: 5px !important;
  }
  .VueCarousel-dot-container {
    position: absolute;
    margin: 0 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    .VueCarousel-dot {
      width: 10px !important;
      height: 10px !important;
      outline: none !important;
      padding: 20px 8px !important;
      margin: 0 !important;
      border-radius: 0 !important;
      @media (max-width: 767px) {
        padding: 0 8px !important;
      }
    }
  }
}
.achievements-carousel-category {
  .VueCarousel {
    padding: 0 10px;
  }
  .VueCarousel-inner--center {
    justify-content: space-between !important;
  }
  .VueCarousel-navigation--disabled {
    opacity: 0 !important;
  }
  .VueCarousel-navigation-button {
    color: #FFF !important;
    background-color: #3859A6 !important;
    font-size: 17px !important;
    @media (max-width: 767px) {
      padding: 5px !important;
    }
  }
  .VueCarousel-navigation-next {
    right: 5px !important;
  }
  .VueCarousel-navigation-prev {
    left: 5px !important;
  }
  .VueCarousel-pagination {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.arrow-up {
  position: absolute;
  top: 27px;
  left: calc(50% - 20px);
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #041D59;
}
.arrow-up-category {
  display: none !important;
}

.tooltip-box {
  position: absolute;
  padding: 20px 10px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12px;
  @media (max-width: 991px) {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    display: none;
  }
}
.tooltip-box-category {
  display: none !important;
}
.achievements-carousel-category {
  display: flex;
  align-items: center;
  height: 110px;
  width: 100%;
  background-color: #FFF;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px) {
    display: block !important;
    max-height: 80px;
    background-color: transparent;
  }
  .achievement-box {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    @media (max-width: 991px) {
      padding: 15px 0;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 768px) {
      justify-content: center;
    }
    .image {
      height: 40px;
      width: auto;
      @media (max-width: 1199px) {
        height: 30px;
      }
      @media (max-width: 991px) {
        height: 25px;
      }
    }
    .ach-title {
      padding-left: 10px;
      font-size: 15px;
      color: #3859A6;
      display: flex;
      align-items: center;
      margin: 0;
      height: 40px;
      @media (max-width: 1199px) {
        font-size: 14px;
      }
      @media (max-width: 991px) {
        font-size: 10px;
        padding-left: 8px;
      }
    }
  }
}
.achievements-carousel {
  height: 255px;
  width: 100%;
  background-color: #F5F5F5;
  @media (max-width: 768px) {
    height: 155px;
  }
  .placeholder {
    &__image {
      padding-bottom: calc(100% * 1 / 1.2);
      border-radius: 20px;
    }
    padding-top: 25px;
    padding-bottom: 75px;
    @media (max-width: 768px) {
      padding-top: 15px;
      padding-bottom: 55px;
    }
  }
  .achievement-box {
    padding: 50px 0;
    @media (max-width: 768px) {
      padding: 22px 0 10px 0;
    }
    .image {
      max-width: 100px;
      height: 80px;
      width: auto;
      @media (max-width: 991px) {
        height: 55px;
      }
      @media (max-width: 768px) {
        height: 50px;
      }
    }
    .ach-title {
      font-size: 18px;
      color: #3859A6;
      margin: 25px 0;
      @media (max-width: 991px) {
        font-size: 14px;
        height: 40px;
        margin: 15px 0 0 0;
      }
    }
  }
}
</style>
