<template>
  <div id="home" class="bg-cl-white-smoke">
    <!-- Add WebSite rich snippet -->
    <div class="hidden" itemscope itemtype="http://schema.org/WebSite">
      <link itemprop="url" href="https://artykulygrzewcze.pl/">
      <meta itemprop="name" content="AG Grzejniki Design">
      <meta
        itemprop="alternateName"
        content="AG Grzejniki Design - Artykulygrzewcze.pl - największy wybór grzejników dekoracyjnych"
      >
      <form itemprop="potentialAction" itemscope itemtype="http://schema.org/SearchAction">
        <meta
          itemprop="target"
          itemtype="http://schema.org/EntryPoint"
          content="https://artykulygrzewcze.pl/kategorie-produktow?query={search_term_string}"
        >
        <input itemprop="query-input" required name="search_term_string">
        <input type="submit">
      </form>
    </div>
    <LazyHydrate when-idle>
      <breadcrumbs class="bg-cl-white visible-xs" :routes="[]" />
    </LazyHydrate>
    <section class="banner-carousel container px8 pointer">
      <banners-carousel />
    </section>
    <LazyHydrate when-idle>
      <section class="container px15">
        <achievements-carousel />
      </section>
    </LazyHydrate>
    <LazyHydrate when-visible>
      <section class="container px15">
        <h2 class="align-center cl-alternative my10">
          {{ $t("Bestsellers") }}
        </h2>
        <div class="row center-xs">
          <lazy-hydrate :trigger-hydration="!loading" v-if="isLazyHydrateEnabledBest">
            <product-listing columns="3" :is-main-page="true" :products="everythingBestsellerCollection" />
          </lazy-hydrate>
          <no-ssr v-else>
            <product-listing columns="3" :is-main-page="true" :products="everythingBestsellerCollection" />
          </no-ssr>
        </div>
      </section>
    </LazyHydrate>
    <LazyHydrate when-visible>
      <promoted-offers />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <section class="container px15 mt50">
        <h2 class="align-center cl-alternative my10">
          {{ $t("Nowości") }}
        </h2>
        <div class="row center-xs">
          <lazy-hydrate :trigger-hydration="!loading" v-if="isLazyHydrateEnabled">
            <product-listing columns="3" :is-main-page="true" :products="everythingNewCollection" />
          </lazy-hydrate>
          <no-ssr v-else>
            <product-listing columns="3" :is-main-page="true" :products="everythingNewCollection" />
          </no-ssr>
        </div>
      </section>
    </LazyHydrate>
    <no-ssr>
      <authorized-distributor />
    </no-ssr>
    <LazyHydrate when-visible>
      <section class="container px15 mt50">
        <h2 class="align-center cl-alternative my10">
          {{ $t("Exclusive collections") }}
        </h2>
        <div class="row center-xs">
          <lazy-hydrate :trigger-hydration="!loading" v-if="everythingExclusiveCollection">
            <products-carousel :products="everythingExclusiveCollection" />
          </lazy-hydrate>
          <no-ssr v-else>
            <products-carousel :products="everythingExclusiveCollection" />
          </no-ssr>
        </div>
      </section>
    </LazyHydrate>
    <LazyHydrate when-visible>
      <section class="container px15 mt60">
        <no-ssr>
          <inspirations-carousel />
        </no-ssr>
      </section>
    </LazyHydrate>
    <LazyHydrate when-visible>
      <reviews-carousel />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <blog-preview />
    </LazyHydrate>
  </div>
</template>

<script>
import { isServer } from '@vue-storefront/core/helpers'
import LazyHydrate from 'vue-lazy-hydration'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import Home from '@vue-storefront/core/pages/Home'
import { Logger } from '@vue-storefront/core/lib/logger'
import { mapGetters } from 'vuex'
import config from 'config'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { RecentlyViewedModule } from '@vue-storefront/core/modules/recently-viewed'
import ProductListing from 'theme/components/core/ProductListing'
import PromotedOffers from 'theme/components/theme/blocks/PromotedOffers/PromotedOffers'
import BannersCarousel from 'theme/components/core/BannersCarousel.vue'
import InspirationsCarousel from 'theme/components/core/InspirationsCarousel.vue'
import AuthorizedDistributor from 'theme/components/core/AuthorizedDistributor'
import ReviewsCarousel from 'theme/components/core/ReviewsCarousel'
import AchievementsCarousel from 'theme/components/core/AchievementsCarousel.vue'
import Breadcrumbs from 'theme/components/core/Breadcrumbs.vue';
import NoSSR from 'vue-no-ssr';
import ProductsCarousel from 'theme/components/core/ProductsCarousel.vue'
import BlogPreview from 'theme/components/core/BlogPreview.vue'

export default {
  mixins: [Home],
  components: {
    AchievementsCarousel,
    InspirationsCarousel,
    AuthorizedDistributor,
    BannersCarousel,
    ProductListing,
    PromotedOffers,
    Breadcrumbs,
    ReviewsCarousel,
    'no-ssr': NoSSR,
    LazyHydrate,
    ProductsCarousel,
    BlogPreview
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn'], 'homepage'),
    latestPosts () {
      return this.$store.getters['wordpress/posts'].slice(0, 3)
    },
    everythingBestsellerCollection () {
      return this.$store.state.homepage.bestsellers
    },
    everythingExclusiveCollection () {
      return this.$store.state.homepage.exclusive_collections
    },
    everythingNewCollection () {
      return this.$store.state.homepage.new_collection
    },
    isLazyHydrateEnabledBest () {
      return config.ssr.lazyHydrateFor.some(
        field => ['homepage', 'homepage.bestsellers'].includes(field)
      )
    },
    isLazyHydrateEnabledExclusive () {
      return config.ssr.lazyHydrateFor.some(
        field => ['homepage', 'homepage.exclusive_collections'].includes(field)
      )
    },
    isLazyHydrateEnabled () {
      return config.ssr.lazyHydrateFor.some(
        field => ['homepage', 'homepage.new_collection'].includes(field)
      )
    }
  },
  methods: {
  },
  beforeCreate () {
    registerModule(RecentlyViewedModule)
  },
  async beforeMount () {
    if (this.$store.state.__DEMO_MODE__) {
      const onboardingClaim = await this.$store.dispatch('claims/check', { claimCode: 'onboardingAccepted' })
      if (!onboardingClaim) { // show onboarding info
        this.$bus.$emit('modal-toggle', 'modal-onboard')
        this.$store.dispatch('claims/set', { claimCode: 'onboardingAccepted', value: true })
      }
    }
  },
  mounted () {
    if (!this.isLoggedIn && localStorage.getItem('redirect')) this.$bus.$emit('modal-show', 'modal-signup')
  },
  watch: {
    isLoggedIn () {
      const redirectObj = localStorage.getItem('redirect')
      if (redirectObj) this.$router.push(redirectObj)
      localStorage.removeItem('redirect')
    }
  },
  async asyncData ({ store, route }) { // this is for SSR purposes to prefetch data
    Logger.info('Calling asyncData in Home (theme)')()

    await Promise.all([
      store.dispatch('homepage/fetchNewCollection'),
      store.dispatch('homepage/loadBestsellers'),
      store.dispatch('homepage/loadExclusiveCollections')
    ])
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) next() // SSR no need to invoke SW caching here
    else if (!from.name) { // Loading products to cache on SSR render
      next(vm =>
        Promise.all([
          vm.$store.commit('ui/routeChanged', { to: to, from: from }),
          vm.$store.dispatch('homepage/fetchNewCollection'),
          vm.$store.dispatch('homepage/loadBestsellers'),
          vm.$store.dispatch('homepage/loadExclusiveCollections')
        ]).then(res => {
          vm.loading = false
        })
      )
    } else {
      next(async vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  }
}
</script>
