<template>
  <div class="media-gallery-carousel-related w-100 bg-cl-white">
    <no-ssr>
      <carousel
        v-bind="sliderConfig"
        @pageChange="setMuted"
        :infinite="true"
      >
        <slide
          class="image-box"
          v-for="(product, index) in products"
          v-if="isVisibleOnNewPage(product.news_to_date) || !isMainPage"
          :key="index"
          @slideclick="handleSlideClick(index)"
        >
          <product-tile
            class="collection-product"
            :product="product"
            :is-main-page="true"
            :prevent-click="true"
            :position="index"
            :is-not-show-hover="isNotShowHover"
            list="carousel"
          />
        </slide>
        <slide class="image-box" />
      </carousel>
    </no-ssr>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { onlineHelper } from '@vue-storefront/core/helpers'
import ProductTile from 'theme/components/core/ProductTile'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'ProductGalleryCarousel',
  components: {
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR,
    ProductTile
  },
  props: {
    products: {
      type: null,
      required: true
    },
    isNotShowHover: {
      type: Boolean,
      default: false
    },
    isMainPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sliderConfig: {
        perPageCustom: [[576, 3], [990, 4]],
        centerMode: true,
        touchDrag: true,
        resistanceCoef: 400,
        navigationEnabled: true,
        paginationActiveColor: '#3859A6',
        paginationColor: '#D9D9D9',
        paginationPosition: 'bottom',
        paginationEnabled: true,
        navigationNextLabel: '&#10095;',
        navigationPrevLabel: '&#10094;'
      },
      currentPage: 0
    }
  },
  computed: {
    isOnline () {
      return onlineHelper.isOnline
    },
    productLink () {
      var t = {}
      for (var k = 0; k < this.products.length; k++) {
        t[k] = formatProductLink(this.products[k], currentStoreView().storeCode)
      }
      return t
    }
  },
  methods: {
    currentDate () {
      let today = new Date()
      return today
    },
    expireDate (p) {
      if (p) {
        // let copy = this.product.news_to_date
        let expireAll = Date.parse(p)
        return expireAll
      } else {
        return 'none'
      }
    },
    isExpire (p) {
      if ((this.expireDate(p) === 'none') || (this.currentDate() > this.expireDate(p))) {
        return true
      } else {
        return false
      }
    },
    isVisibleOnNewPage (p) {
      if (this.isExpire(p)) {
        return false
      } else {
        return true
      }
    },
    handleSlideClick (index) {
      this.$router.push(this.productLink[index])
    },
    setMuted (currentPage) {
      this.currentPage = currentPage
    }
  }
}
</script>

<style lang="scss">
.media-gallery-carousel-related {
  .VueCarousel-inner--center {
    justify-content: end !important;
  }
  .VueCarousel-navigation--disabled {
    opacity: 0 !important;
  }
  .VueCarousel-navigation-button {
    color: #FFF !important;
    background-color: #3859A6 !important;
    font-size: 20px !important;
  }
  .VueCarousel-navigation-next {
    right: 27px !important;
    @media (max-width: 767px) {
      right: 20px !important;
    }
    margin: 0 !important;
  }
  .VueCarousel-navigation-prev {
    left: 27px !important;
    @media (max-width: 767px) {
      left: 20px !important;
    }
    margin: 0 !important;
  }
  // .VueCarousel-pagination {
  //   padding-bottom: 10px;
  // }
  .VueCarousel-dot-container {
    margin: 0 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    .VueCarousel-dot {
      width: 10px !important;
      height: 10px !important;
      outline: none !important;
      padding: 8px !important;
      margin: 0 !important;
      border-radius: 0 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.product {
  &.collection-product {
    box-sizing: border-box;
    padding: 5px 8px;
  }
}
.image-box {
 background: #FFFFFF;
}
.collection-product {
  // .product-link {
  //   display: block;
  //   padding: 0 15px;
  // }

  .product-image {
    // height: auto;
    // will-change: opacity;

    img {
      // max-width: 100%;
      // max-height: 100%;
      // height: auto;
      // vertical-align: bottom;
    }
  }
}
</style>
