<template>
  <div class="media-gallery-carousel1">
    <div class="carousel-box">
      <no-ssr>
        <carousel
          v-bind="sliderConfig"
          @pageChange="setMuted"
          :infinite="true"
        >
          <slide
            class="image-box"
            v-for="(banner, index) in validBanners"
            :key="index"
            @slideclick="handleSlideClick(banner.link)"
          >
            <div class="offer">
              <img v-if="currentPage === index" class="offer-image" alt="offer-image" width="1350" height="636" v-lazy="getThumbnailPath(banner.urlDesktop, banner.urlMobile)">
            </div>
          </slide>
        </carousel>
        <div slot="placeholder" class="row m0">
          <div class="col-xs-12 placeholder bg-cl-gainsboro" />
        </div>
      </no-ssr>
    </div>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { onlineHelper } from '@vue-storefront/core/helpers'
import { MobileDetected } from './mobileDetected.ts'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'

export default {
  name: 'ProductGalleryCarousel',
  components: {
    'Carousel': () => import('vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import('vue-carousel').then(Slider => Slider.Slide),
    'no-ssr': NoSSR
  },
  props: {
  },
  data () {
    return {
      validBanners: [],
      sliderConfig: {
        perPage: 1,
        autoplayTimeout: 6000,
        // paginationEnabled: false,
        paginationActiveColor: '#3859A6',
        paginationColor: '#D9D9D9',
        paginationPosition: 'bottom',
        infiniteLoop: true,
        centerMode: true,
        touchDrag: true,
        scrollPerPage: true,
        autoplay: true,
        loop: true
      },
      currentPage: 0
    }
  },
  mixins: [MobileDetected],
  mounted () {
    if (this.data) {
      this.blocksContent()
    }
  },
  watch: {
    data: function () {
      this.blocksContent()
    }
  },
  computed: {
    isOnline () {
      return onlineHelper.isOnline
    },
    data () {
      return this.$store.getters[`cmsBlock/cmsBlocks`]
    }
  },
  methods: {
    blocksContent () {
      if (this.data && this.data.length) {
        let banners = this.data
        for (let banner of banners) {
          if (banner.identifier.includes('main')) {
            if (banner.content && banner.content.length) {
              let bannerInfo = banner.content.replace('<p>', '').replace('</p>', '')
              bannerInfo = JSON.parse(bannerInfo)
              if (bannerInfo.urlDesktop && bannerInfo.urlMobile && bannerInfo.from && bannerInfo.to && bannerInfo.link) {
                const now = new Date()
                let pattern = /(\d{2})\.(\d{2})\.(\d{4})/
                let fromDate = new Date(bannerInfo.from.replace(pattern, '$3-$2-$1').replace(/-/g, '/'))
                let toDate = new Date(bannerInfo.to.replace(pattern, '$3-$2-$1').replace(/-/g, '/'))
                if (fromDate < now && toDate > now) {
                  this.validBanners.push(bannerInfo)
                }
              }
            }
          }
        }
        this.validBanners = this.validBanners.sort((a, b) => {
          let left = Number(a.order)
          let right = Number(b.order)
          if (left < right) { return -1 }
          if (left > right) { return 1 }
          return 0
        })
      }
    },
    getThumbnailPath (urlDesktop, urlMobile) {
      if (!this.isMobile) {
        return _thumbnailHelper(urlDesktop, 1400, 700, 'banners')
      } else {
        return _thumbnailHelper(urlMobile, 767, 445, 'banners')
      }
    },
    handleSlideClick (link) {
      this.$router.push(this.localizedRoute(link))
    },
    setMuted (currentPage) {
      this.currentPage = currentPage
    }
  }
}
</script>

<style lang="scss">
.media-gallery-carousel1 {
  .VueCarousel {
    max-height: 100%;
  }
  .VueCarousel-dot-container {
    margin: 0 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    .VueCarousel-dot {
      width: 13px !important;
      height: 13px !important;
      outline: none !important;
      padding: 20px 8px !important;
      margin: 0 !important;
      border-radius: 0 !important;
      @media (max-width: 767px) {
        padding: 5px 8px !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@keyframes mymove {
  from {top: -350px;}
  to {top: 110%;}
}
.animation-container {
  overflow: hidden;
  position: relative;
  opacity: 1;
  &:before {
    content: "";
    display: block;
    position: absolute;
    background-color: #e0e0e0;
    z-index: 1;
    transform: rotate(-15deg);
    top: -350px;
    left: -100px;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    @media (max-width: 767px) {
      width: 300%;
      height: 300%;
    }
  }
  &.vue-in:before {
    animation: mymove 1s;
    animation-delay: .5s;
    animation-fill-mode: forwards;
  }
}
.media-gallery-carousel1 {
  padding-top: calc(100% * 1/1.9);
  @media (max-width: 767px) {
    padding-top: calc(100% * 1/0.95);
  }
  position: relative;
  .carousel-box {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  width: 100%;
}
.offer {
    background-position: center right;
    // height: 635px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    transition: 0.3s all;
    .offer-image {
      width: 100%;
      height: auto;
    }
    &:hover {
      opacity: 0.9;
    }
}
.placeholder {
  position: absolute;
  border-radius: 20px;
  height: 90%;
  width: 100%;
}
</style>
