<template>
  <section class="container px15 mt40 blog">
    <div class="row">
      <p class="bg-cl-white col-xs-12 center-xs py20 m0 cl-alternative h2 weight-500 brdr-bottom-2 brdr-cl-white-smoke">
        {{ $t('Porady') }}
      </p>
      <div v-for="post in latestPosts" class="blog-content bg-cl-white col-xs-12 col-md-4 pl0 no-padding-mobile center-xs" :key="post.id">
        <router-link :to="localizedRoute('/porady') + '/' + post.slug">
          <img v-lazy="post.featuredImage.node.medium" :alt="post.title" width="440" height="312" class="blog-image">
          <div class="pl15 mb15 start-xs">
            <p class="weight-500">
              {{ post.title }}
            </p>
            <span class="fs-medium-small cl-dark-blue">
              {{ $t('Read more') }} >
            </span>
          </div>
        </router-link>
      </div>
    </div>
    <div class="row bg-cl-white end-xs py20 learn-more brdr-top-2 brdr-cl-white-smoke">
      <router-link :to="localizedRoute('/porady')" class="cl-dark-blue fs-medium-small">
        {{ $t('Learn More') }} >
      </router-link>
    </div>
  </section>
</template>

<script>
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { Wordpress } from 'src/modules/vsf-wordpress'

export default {
  components: {
  },
  data () {
    return {
    }
  },
  beforeCreate () {
    registerModule(Wordpress)
  },
  computed: {
    latestPosts () {
      return this.$store.getters['wordpress/posts'].slice(0, 3)
    }
  },
  created () {
    return this.$store.dispatch('wordpress/loadPosts', { first: 3 })
  }
}
</script>

<style lang="scss" scoped>
.no-padding-mobile {
  @media (max-width: 767px) {
    padding: 0 !important;
  }
}
.learn-more {
  padding-right: 60px;
  @media (max-width: 767px) {
    padding-right: 10px;
  }
}
.blog-content {
  @media (max-width: 767px) {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.blog {
  .blog-image {
    width: auto;
    max-width: 100%;
    height: 312px;
    max-height: 312px;
    object-fit: cover;
    margin-top: 10px;
    @media (max-width: 767px) {
      display: flex;
      margin: 10px auto 0 auto;
      width: 100%;
      max-width: 300px;
      max-height: auto;
      height: auto;
    }
  }
}
</style>
